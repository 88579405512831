@import "../../global/styles/variables";
@import "../../global/styles/mixins";

html {
	body {
		#main {
			#page_header {
				height : auto;

				.header_swiper {
					.swiper-slide {
						align-items     : center;
						display         : flex;
						justify-content : center;

						img {
							min-height : 270px;
						}
					}

					.swiper-button-prev {
						left : 0;
					}

					.swiper-button-next {
						right : 0;
					}

					.swiper-button-prev,
					.swiper-button-next {
						align-items     : center;
						background      : $color-pink;
						display         : flex;
						height          : 50px;
						justify-content : center;
						width           : 50px;
						@include media-breakpoint-down("lg") {
							opacity    : .35;
							transition : opacity .3s;
							&:hover {
								opacity : 1;
							}
						}

						&:after {
							color       : $color-white;
							font-size   : 30px;
							line-height : 0;
						}
					}
				}
			}

			#page {
				section {
					&.flash-alert {
						padding : 20px 0;

						.icon {
							font-size    : 25px;
							margin-right : 25px;
						}

						.text {
							p {
								margin : 0;
							}
						}
					}

					&.about {
						.info-bloc {
							align-items     : flex-start;
							display         : flex;
							justify-content : flex-start;
							margin-bottom   : 60px;
							padding-left    : 40px;
							@include media-breakpoint-down("lg") {
								padding-left : 0;
							}
							@include media-breakpoint-down("sm") {
								//flex-direction : column;
								margin-bottom : 30px;
							}

							.icon {
								margin-right : 15px;
								@include media-breakpoint-down("sm") {
									//margin-bottom : 15px;
									//margin-right  : 0;
								}

								img {
									width : 55px;
								}
							}
						}

						iframe {
							margin-left  : auto;
							margin-right : auto;
							max-width    : 100%;
							@include media-breakpoint-up("xxl") {
								margin-top : -195px;
							}
						}
					}

					&.stopover {
						background      : url(../../images/index/stopover_background.svg) no-repeat bottom center;
						background-size : contain;
						padding-bottom  : 75px;
						@include media-breakpoint-down("lg") {
							background-size : auto 150px;
						}

						.swiper {
							.swiper-nav{
								display : grid;
								grid-template-columns : 1fr 1fr;
								margin-top : 2rem;
								place-items : center;
								.swiper-button {
									&-prev,
									&-next {
										display : flex;
										margin: 0;
										position : relative;
										bottom : 0;
										top    : auto;
										font-size : 4rem;
										width : 5rem;
										height: 5rem;
										transform : none;
										color: $color-pink;
										background: transparent;
										aspect-ratio: 1;
										border-radius: 50%;
										will-change: color, background;
										transition: color $transition-default, background $transition-default;
										&:hover {
											color: $color-white;
											background: $color-pink;
										}
										&:after {
											display : none;
										}
									}
								}
							}
						}

						h2 {
							font-family    : "Raleway", sans-serif;
							font-size      : 53px;
							text-transform : uppercase;
							@include media-breakpoint-down("md") {
								font-size   : 40px;
								line-height : 45px;
							}

							.overline {
								color       : $color-bordeaux;
								font-size   : 20px;
								font-weight : 400;
							}
						}

						.description {
							font-size : 20px;
						}

						.icons {
							align-items     : center;
							display         : flex;
							justify-content : space-between;
							margin          : 60px 0;
							@include media-breakpoint-down("md") {
								//flex-direction : column;
								img {
									&:nth-child(odd) {
										width : 20%;
									}

									&:nth-child(even) {
										width : 5%;
									}
								}
							}
						}
					}

					&.rooms {
						h2 {
							padding-right : 50px;

							.overline {
								color          : $color-gray;
								text-transform : uppercase;
							}
						}

						.text {
							margin-bottom : 115px;
							@include media-breakpoint-up("lg") {
								margin-top : 150px;
							}
						}
					}

					&.tourism {
						h2 {
							padding-right : 50px;

							.overline {
								color          : $color-gray;
								text-transform : uppercase;
							}
						}

						.logos {
							align-items     : center;
							display         : flex;
							justify-content : center;
							margin-bottom   : 50px;
							margin-top      : 35px;

							a {
								img {
									height       : 60px;
									margin-right : 35px;
								}

								&:last-of-type {
									img {
										height : 120px;
									}
								}
							}
						}

						.text {
							margin-bottom : 115px;
							@include media-breakpoint-up("lg") {
								margin-top : 150px;
							}
						}
					}
				}

				.swiper {
					margin-bottom : 35px;
					@include media-breakpoint-up("md") {
						padding : 0 40px;
					}

					.swiper-slide {
						.caption {
							align-items     : stretch;
							background      : $color-white;
							border          : 1px solid $color-pink;
							bottom          : 0;
							color           : $color-black;
							display         : flex;
							font-size       : 17px;
							font-weight     : 500;
							justify-content : flex-start;
							line-height     : 20px;
							position        : absolute;
							right           : 0;
							text-transform  : uppercase;
							@include media-breakpoint-down("lg") {
								font-size : 14px;
							}
							@include media-breakpoint-down("sm") {
								font-size      : 12px;
								letter-spacing : 1px;
								position       : relative;
							}

							.icon {
								align-content   : center;
								background      : $color-pink;
								display         : flex;
								justify-content : center;
								margin-right    : 10px;
								padding         : 20px;
								@include media-breakpoint-down("sm") {
									padding : 10px;
									img {
										height : 15px;
									}
								}
							}

							.content {
								padding : 20px;
								@include media-breakpoint-down("sm") {
									padding : 10px;
								}
							}

							i {
								color : $color-pink;
							}

							img {
								height : auto;
							}
						}
					}

					.swiper-button-prev,
					.swiper-button-next {
						align-items     : center;
						background      : $color-pink;
						display         : flex;
						height          : 40px;
						justify-content : center;
						padding         : 10px;
						width           : 40px;

						&:after {
							content     : url(../../images/slider_arrow.svg);
							line-height : 0;
						}

						@include media-breakpoint-down("md") {
							display : none;
						}
					}

					.swiper-button-prev {
						left      : 0;
						transform : rotate(180deg);
					}

					.swiper-button-next {
						right : 0;
					}

					.swiper-pagination {
						align-items     : center;
						display         : flex;
						flex-wrap       : wrap;
						justify-content : center;
						padding         : 15px 0;

						&.swiper-pagination-clickable {
							.swiper-pagination-bullet {
								cursor : pointer;
							}
						}

						.swiper-pagination-bullet {
							background    : $color-gray;
							border-radius : 50%;
							height        : 15px;
							margin        : 10px;
							transition    : background-color $transition-default;
							width         : 15px;
							@include media-breakpoint-down("sm") {
								height : 20px;
								margin : 15px;
								width  : 20px;
							}

							&.swiper-pagination-bullet-active {
								background : $color-pink;
							}
						}
					}
				}
			}
		}
	}
}
