$color-white             : #FFFFFF;
$color-black             : #161211;
$color-dark              : #190106;
$color-pink              : #D54262;
$color-bordeaux          : #7A0821;
$color-gray              : #CECBCB;
$color-light-gray        : #F5F4EE;
$color-beige             : #FCFAEE;
$text-color              : #333333;
$default-font-size       : 15px;
$site-colors             : (
		"white" : $color-white,
		"black" : $color-black,
		"dark" : $color-dark,
		"pink" : $color-pink,
		"bordeaux" : $color-bordeaux,
		"gray" : $color-gray,
		"light-gray" : $color-light-gray,
		"beige" : $color-beige,
);
$title-font-map          : (
		"1" : (
				"font-size" : 84px,
				"font-weight" : 400,
				"font-family" : "Parisienne, serif",
		),
		"2" : (
				"font-size" : 36px,
				"font-weight" : 900,
				"line-height" : 43px,
				"margin-bottom" : 30px
		),
		"3" : (
				"font-size" : 21px,
				"font-weight" : 400,
				"line-height" : 25px,
				"margin-bottom" : 20px,
				"text-transform" : uppercase,
				"letter-spacing" : 2px
		),
		"4" : (
				"font-size" : 17px,
				"font-weight" : 700,
				"line-height" : 20px,
				"margin-bottom" : 10px
		),
);
$weights                 : (
		"thin" : 100,
		"extralight" : 200,
		"light" : 300,
		"regular" : 400,
		"medium" : 500,
		"semibold":600,
		"bold" :700,
		"extrabold" : 800,
		"black" : 900
);
$grid-breakpoints        : (
		xs: 0,
		sm: 576px,
		md: 768px,
		lg: 992px,
		xl: 1200px,
		xxl: 1400px
) !default;
$transition-default      : .25s ease-in-out;
$hamburger-layer-width   : 35px !default;
$hamburger-layer-height  : 3px !default;
$hamburger-layer-spacing : 5px !default;
$hamburger-layer-color   : $color-bordeaux !default;
$hamburger-hover-opacity : 1 !default;